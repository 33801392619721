<template>
	<div class="footers">
		<footer class="main-footer">
			<div class="row">
				<div class="columns column3">
					<div class="footer-logo" />
				</div>
				<div class="columns column3">
					<h4>{{ defaults[locale].website.hotelName }}</h4>
					<p class="address">
						<span>{{ defaults[locale].website.street }} {{ defaults[locale].website.number }}</span>
						<span>{{ defaults[locale].website.postalCode }} {{ defaults[locale].website.city }}</span>
						<span>{{ defaults[locale].website.country }}</span>
					</p>
				</div>
				<div class="columns column3">
					<h4>{{ $t('contact') }}</h4>
					<p>
						<a :href="`tel:${$phoneRaw(defaults[locale].website.phone)}`">
							<span>{{ defaults[locale].website.phone }}</span>
						</a>
						<a :href="`mailto:${defaults[locale].website.email}`">
							<span>{{ defaults[locale].website.email }}</span>
						</a>
					</p>
					<social-media :socials="socials" />
				</div>
				<div class="columns column3">
					<h4>{{ $t('quickLinks') }}</h4>
					<p>
						<nuxt-link v-for="item in footermenu[locale]" :key="item.filename" :to="item.filename">
							{{ item.header }}
						</nuxt-link>
					</p>
				</div>
			</div>
		</footer>
		<footer class="author-footer">
			<div class="row">
				<div class="columns column12">
					<div class="border" />
					<p>
						<a
							href="https://www.porterforhotels.com/hotel-websites"
							target="_blank"
							rel="noopener noreferrer"
							title="Hotel website design by Porter"
						>
							Hotel Website Design
						</a>
						<span>by:</span>
						<a href="https://porterforhotels.com" target="_blank">
							<img
								src="https://www.porterforhotels.com/images/porter-beeldmerk-gold.png"
								loading="lazy"
								alt="Porter logo"
							/>
							Porter
						</a>
					</p>
				</div>
			</div>
		</footer>
	</div>
</template>

<script setup>
const { data: footer } = await useWebsiteFetch('footermenu');
const { footermenu } = footer.value;
const { locale } = useI18n();

const { defaults, socials, fetchDefaults } = useDefaults();

await fetchDefaults();
</script>

<style lang="scss" scoped>
.footers {
	color: $footer-color;
	background: $footer-background-color;

	a,
	span {
		color: $footer-color;
		text-decoration: none;
	}

	h3,
	h4 {
		color: $footer-color;
		letter-spacing: 1px;
	}

	h4 {
		font-size: 1.4em;
	}
}

.main-footer {
	p {
		font-size: 0.9em;
	}

	a,
	span {
		display: block;
	}

	a:hover {
		text-decoration: underline;
	}

	.footer-logo {
		width: 220px;
		height: 102px;
		background: url('~/assets/images/logo-quinta-das-maravilhas-full-white.png') no-repeat center center;
		background-size: 220px;
		margin: 0 0 30px;
	}
}

.author-footer {
	font-size: 0.8em;
	padding: 5px 0;
	text-align: center;
	width: 100%;

	.border {
		background: #444;
		width: 100%;
		height: 1px;
	}

	img {
		max-width: 20px;
		margin: 0 1px 0 2px;
	}
}

@media (max-width: 1080px) {
	footer .columns {
		margin: 0 auto 20px;
	}
}

@media (max-width: 580px) {
	footer {
		text-align: center;

		&.main-footer {
			padding: 50px 0;
		}

		.columns {
			margin: 0 auto 30px;
		}

		&.author-footer {
			.columns {
				margin: 25px auto;
			}

			.border {
				margin: 0 0 25px;
			}
		}
	}

	.main-footer .footer-logo {
		margin: 0 auto 20px;
	}
}
</style>
