<template>
	<nav v-if="breadcrumbs.length" class="breadcrumbs align-center">
		<div class="row">
			<div class="columns column12">
				<div>
					<span>
						<nuxt-link :to="localePath('/', locale)">
							{{ home }}
						</nuxt-link>
						<span>></span>
					</span>
					<span v-for="(item, index) in breadcrumbs" :key="item.filename">
						<nuxt-link v-if="index + 1 !== breadcrumbs.length" :to="item.filename">
							{{ item.header }}
						</nuxt-link>
						<span v-else>{{ item.header }}</span>
						<span v-if="!(index == breadcrumbs.length - 1)">></span>
					</span>
				</div>
			</div>
		</div>
	</nav>
</template>

<script setup>
const { locale } = useI18n();

defineProps({
	home: { type: String, default: 'Home' },
	breadcrumbs: { type: Array, default: () => [] },
});
</script>

<style lang="scss" scoped>
.breadcrumbs {
	padding: 30px 0;
	font-size: 13px;

	a,
	span {
		margin: 0 10px 0 0;
	}

	&.no-slider {
		margin-top: 84px;
	}

	ol {
		list-style-type: none;

		li {
			float: left;
		}
	}
}
</style>
