<template>
	<div class="submenu-container">
		<span class="mobile-dropdown" :class="{ active }" @click="active = !active" />
		<div class="submenu" :class="{ active: hideAll ? resetState() : active }">
			<ul>
				<li v-for="item in subitems" :key="item.filename">
					<nuxt-link :to="`${item.filename}`" @click="hideMobileNav">
						{{ item.header }}
					</nuxt-link>
				</li>
			</ul>
		</div>
	</div>
</template>

<script setup>
defineProps({
	subitems: { type: Array, default: () => [] },
	hideAll: { type: Boolean, default: false },
});

const emit = defineEmits(['hide-mobile-nav']);

const active = ref(false);

const resetState = () => {
	active.value = false;
};

const hideMobileNav = () => {
	emit('hide-mobile-nav');
};
</script>

<style lang="scss" scoped>
.submenu-container {
	position: relative;
}

a {
	font-weight: 400;
	color: #504a4a;

	&.active,
	&:hover {
		color: $cta-color;
	}
}

.mobile-dropdown {
	width: 22px;
	height: 22px;
	background: url('~/assets/images/icon-form-dropdown.png') center center no-repeat;
	line-height: 22px;
	vertical-align: middle;
	display: none;
	cursor: pointer;
	margin-top: -3px;


	&.active {
		transform: rotate(180deg);
	}
}

.submenu {
	ul {
		display: none;
		background: #f9f3ec;
		position: absolute;
		top: 5px;
		left: 0;
		text-align: left;
		padding: 7px;

		&.active {
			display: block;
		}

		li {
			padding: 5px 0;
			list-style: none;

			a {
				margin: 0 10px;
				text-decoration: none;
				font-size: 13px;
				font-weight: 700;
				white-space: nowrap;
				letter-spacing: 1px;
			}
		}
	}

	&.active ul {
		display: block;
	}
}

@media (max-width: 1200px) {
	.mobile-dropdown {
		display: none;
	}

	.submenu-container {
		display: block;
		margin: 0 0 10px;
	}

	.submenu {
		ul {
			display: block;
			width: 100%;
			margin: 0 0 10px;
		}
	}
}
</style>
