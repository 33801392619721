<template>
	<transition name="cookie-consent">
		<client-only>
			<div v-if="!consentGiven" class="cookie-consent" :class="position">
				<div v-parse-links v-html="content" />
				<span class="cookie-close" @click="giveConsent">
					<font-awesome-icon icon="fal fa-times" size="1x" />
				</span>
			</div>
		</client-only>
	</transition>
</template>

<script setup>
defineProps({
	content: { type: String, default: '' },
	position: { type: String, default: 'left' },
});

const consentGiven = ref(false);

onMounted(() => {
	if (window.localStorage && window.localStorage.getItem('consentGiven')) {
		consentGiven.value = true;
	}
});

const giveConsent = () => {
	if (window.localStorage) {
		window.localStorage.setItem('consentGiven', 'true');
	}
	consentGiven.value = true;
};
</script>

<style lang="scss" scoped>
.cookie-consent-enter-active {
	transition: opacity 0.4s ease-out;
}

.cookie-consent-leave-active {
	transition: opacity 0.2s ease-out;
}

.cookie-consent-leave-to,
.cookie-consent-enter {
	opacity: 0;
}

.cookie-consent {
	position: fixed;
	width: 90%;
	max-width: 360px;
	bottom: 20px;
	left: 20px;
	z-index: 1000;
	padding: 20px;
	background: $primary-background-color;
	box-shadow: rgba(0 0 0 / 30%) 0 1px 3px 0;
	font-size: 14px;
	color: #000;

	.cookie-close {
		cursor: pointer;
		margin: 0 0 0 20px;
		padding: 2px;
		position: absolute;
		top: 7px;
		right: 10px;
	}

	&.left {
		left: 20px;
	}

	&.right {
		left: auto;
		right: 20px;
	}

	&.middle {
		left: 20px;
		right: 20px;
		margin: 0 auto;
	}
}
</style>
